import React, { Component } from "react";
import { Modal, Button, Icon } from "antd";
import "../buildAndrenovation/index.css";
import "./cofirmModel.css";
export default class ConfirmModel extends Component {

  render() {
      const {visible, OnYes, OnCancle } =this.props
    return (
      <div className="confirmModel">

        <Modal
        className="confirmModel_M"
          title="Modal"
          visible={visible}
          onOk={this.OnYes}
          onCancel={this.onCancel}
          okText="ok"
          cancelText="cancle"
        >
          <div className="header build innner_idv">
            <p className="tilte-ban">Interior Design</p>
            <Icon type="plus" />
          </div>
          <div className="innerItembuild">
            <p>
              We will thoughtfully design stunning solutions that embody your 
              tastes and design style. As your advokate throughout the 
              shopping process, we make sure you have all the necissary tools 
              to make an informed decision.


            </p>
          </div>

          <div className="notesDesignPhone">
            You didn't select interior design as part of your renovation
            process. We feel a designer helps drive the crestive visiton of your
            project and ensures you have all the information necisary to provide an 
            accourate bid. Would you like include any design servicecs?
          </div>
          <div className="underline-Cbtn">
              <p className="underlineBtn" onClick={OnYes}>
                  Yes Please
              </p>
              <p className="underlineBtn" onClick={OnCancle}>
                  No don't need a designer
              </p>
          </div>
        </Modal>
      </div>
    );
  }
}
