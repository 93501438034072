const ADD_FORM_DATA = "ADD_FORM_DATA",
  FORM_DATA_LOADING = "FORM_DATA_LOADING",
  ADD_ERR_FORM_DATA = "ADD_ERR_FORM_DATA",
  SET_MODAL_FORM_DATA = "SET_MODAL_FORM_DATA",
  CLEAR_FORM_DATA = "CLEAR_MODAL_FORM_DATA",

  SET_FORM_DATA = "SET_FORM_DATA";

export {
  ADD_FORM_DATA,
  FORM_DATA_LOADING,
  ADD_ERR_FORM_DATA,
  SET_MODAL_FORM_DATA,
  SET_FORM_DATA,
  CLEAR_FORM_DATA
};
